import React from "react"

const Location = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-location-pin">
        <path
            className="primary"
            fill="currentColor"
            d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zM12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        />
        <path
            className="secondary"
            fill="currentColor"
            d="M12 1a9 9 0 0 1 6.36 15.36l-5.65 5.66a1 1 0 0 1-.71.3V13a3 3 0 0 0 0-6V1z"
        />
    </svg>
)

export default Location