/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui"
import {Link} from "gatsby";
import Layout from "../components/Layout";
import Header from "../components/Header";

export default () => (
    <Layout>
        <Header/>
        <div style={{marginTop: 120, textAlign: 'center'}}>
            <h1>Oops, la page que vous cherchez n'a pas été trouvée.</h1>
            <Link
                aria-label="Lien vers le blog"
                to="/"
                sx={{
                    color: `primary`,
                    textDecoration: `none`,
                    transition: `all 0.3s ease-in-out`,
                    "&:hover, &:focus": { textDecoration: 'underline' },
                }}
            >
                Retour au blog
            </Link>
        </div>
    </Layout>
)