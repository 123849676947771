/** @jsx jsx */
import React from "react";
import {jsx, Flex, Heading} from "theme-ui"
import {animated, config, useSpring} from "react-spring"
import Img from "gatsby-image"
import HeaderBackground from "./HeaderBackground"
import Location from "./Location"
import SocialMedia from "./SocialMedia"
import {graphql, useStaticQuery} from "gatsby";
import Logo from "./Logo";

const Header = () => {
    const data = useStaticQuery(graphql`
    query {
      allPrismicSiteConfig {
        edges {
          node {
            id
            data {
              blog_title
              instagram {
                url
              }
              place
              logo {
                localFile {
                    childImageSharp {
                      fixed(width: 140, height: 140, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                }
              }
            }
          }
        }
      }
    }
  `)

    const siteData = data.allPrismicSiteConfig.edges[0].node.data

    const fadeUpProps = useSpring({
        config: config.slow,
        from: {opacity: 0, transform: `translate3d(0, 30px, 0)`},
        to: {opacity: 1, transform: `translate3d(0, 0, 0)`},
    })
    const fadeUpPropsDelay = useSpring({
        config: config.slow,
        delay: 250,
        from: {opacity: 0, transform: `translate3d(0, 30px, 0)`},
        to: {opacity: 1, transform: `translate3d(0, 0, 0)`},
    })
    const fadeProps = useSpring({config: config.slow, from: {opacity: 0}, to: {opacity: 1}})
    const fadeLongProps = useSpring({config: config.slow, delay: 600, from: {opacity: 0}, to: {opacity: 1}})

    return (
        <Flex as="header" variant="layout.projectHead">
            <HeaderBackground/>
            <div sx={{textAlign: `center`, my: 5, zIndex: 10}}>
                <animated.div style={fadeProps}>
                    <div
                        sx={{
                            overflow: `hidden`,
                            borderRadius: `full`,
                            height: [`100px`, `140px`],
                            width: [`100px`, `140px`],
                            display: `inline-block`,
                            boxShadow: `lg`,
                            "> div:not([data-placeholder='true'])": {
                                height: [`100px !important`, `140px !important`],
                                width: [`100px !important`, `140px !important`],
                            },
                        }}
                    >
                        <Img fixed={siteData.logo.localFile.childImageSharp.fixed} alt="logo" />
                    </div>
                </animated.div>
                <animated.div style={fadeUpProps}>
                    <div
                        sx={{
                            margin: `25px 0`,
                        }}
                    >
                        <Logo/>
                    </div>
                </animated.div>
                <animated.div style={fadeUpPropsDelay}>
                    <Flex
                        sx={{
                            svg: {
                                width: `20px`,
                                height: `20px`,
                                ".primary": {color: `iconPrimary`},
                                ".secondary": {color: `iconSecondary`},
                                mr: 2,
                            },
                            justifyContent: `center`,
                            alignItems: `center`,
                            color: `text`,
                        }}
                    >
                        <Location/> {siteData.place}
                    </Flex>
                </animated.div>
                <div data-testid="social-header" sx={{mt: 4, mb: 6, a: {mx: 2}}}>
                    <animated.div style={fadeLongProps}>
                        <SocialMedia url={siteData.instagram.url}/>
                    </animated.div>
                </div>
            </div>
        </Flex>
    )
}

export default Header