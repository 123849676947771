/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react";

const Logo = () => {
    return (
        <svg version="1.1"
             x="0px" y="0px"
             viewBox="0 0 578.7 207.9"
             sx={{width: [200, 250]}}
        >
            <g>
                <path fill="none" d="M141.7,101.1c2.1-1.4,4.2-3.1,6.2-5l0.8-0.8l-1-0.3c-0.7-0.2-1.5-0.3-2.3-0.3c-1.6,0-3.4,0.5-4.9,1.3
		c-2.7,1.5-6.2,5-7.7,8.4l-0.6,1.3l1.4-0.5C135.8,104.4,139.2,102.8,141.7,101.1z"/>
                <path fill="none" d="M207.5,30c-0.3,0-0.9,0.2-1.5,0.4c-3.3,1.6-7.9,6.9-11.6,15c0,0-9.3,21-18.6,44.6
		c9.5-12.3,24.8-33.3,29.7-48.5C207.2,36,207.8,32.3,207.5,30z"/>
                <path fill="none" d="M339.7,96.1c-0.1-0.6-0.6-1.2-1.1-1.6c-0.5-0.4-1-0.9-1.5-1.1c-1.1-0.5-2.6-0.1-3.7,0.1
		c-1.3,0.3-2.6,0.8-3.9,1.4c-3.5,1.7-6.5,4.3-9.2,7.1c-2.6,2.9-4.9,6-7.2,9.2c-0.9,1.3-1.8,2.5-2.5,3.9c-0.9,1.9-1.5,3.9-1.8,6
		c-0.2,1.3-0.3,2.6,0.3,3.8c0.5,0.9,1.1,1.2,1.9,1.2c0.8,0,1.6-0.2,2.3-0.5c1.3-0.5,2.6-1,3.8-1.6c4.6-2.4,8.4-6.1,11.7-10
		c4.2-5,7.5-10.7,10.5-16.5C339.6,97.2,339.8,96.6,339.7,96.1z"/>
                <path sx={{fill: t => t.colors.text}} d="M214.4,84.2h0.8c0.9,0,2-1.8,2.5-2.3c1.2-1.5,3.2-3.3,2.8-5.4c0-0.3-0.1-0.6-0.3-0.8c-0.4-0.5-1.1-0.5-1.7-0.6
		c-1.9-0.1-3.3,0.8-4.9,1.9c-0.5,0.4-2.1,1.1-2.1,2C211.4,81.6,212.4,83.3,214.4,84.2z"/>
                <path sx={{fill: t => t.colors.text}} d="M578.5,39.3c-0.4-1-1.8-0.8-2.9-0.8c-0.3,0-0.6,0-1,0.1c-0.9,0.1-1.8,0.2-2.8,0.2c-3.5,0.4-7.1,0.8-10.6,1.2
		c-5.4,0.7-10.8,1.4-16.2,2.1c-7.2,1-14.3,2-21.5,3.1c0.1-1.6,0.3-3,0.7-4.5c0-1.2-0.6-2.1-2-2.4l-0.6,0c-1.5,0-2,0.9-3.5,7.6
		c-5.4,1.1-10.8,2-18.6,3.3c-1.2,0.2-2.3,0.4-3.5,0.6c-0.8,0.1-1.2,0.2-2.1,0.3c-0.3,0.1-0.7,0.1-1,0.2c-0.2,0.1-1.2,0.5-1.3,0.7
		c-0.1,0.1-0.2,0.5-0.3,0.8c-0.2,1-0.4,1.3-0.4,2.3c0,0.6,0.3,1,0.9,1c0.8,0,1.7,0.1,2.5,0.1c0.6,0,1.1-0.1,1.7-0.2
		c3.8-0.6,7.6-1.3,11.4-1.8c1.8-0.2,3.6-0.5,5.5-0.7c0.7-0.1,1.4-0.1,2.2-0.2c0.1,0,1.8-0.1,1.8-0.1c-0.5,3.1-2,11-6.1,29.7
		c-0.3,0-0.5,0.1-0.8,0.1c-1.1,0.2-2.2,0.4-3.3,0.6c-1.8,0.3-3.5,0.7-5.3,1c-2.2,0.4-4.3,0.8-6.5,1.3c-2.3,0.5-4.7,0.9-7,1.4
		c-2.3,0.4-4.5,0.9-6.8,1.3c-2,0.4-3.9,0.8-5.9,1.1c-1.1,0.2-2.1,0.4-3.2,0.6c-0.5,0.1-1.1,0.2-1.6,0.3c-0.4,0.1-0.9,0.1-1.2,0.4
		c-0.2,0.2-0.2,0.5-0.2,0.7c-0.1,1.5,0.2,3.6,2.1,3.8c0.5,0,1-0.1,1.5-0.2c0.5-0.1,1.1-0.2,1.6-0.3c1.1-0.2,2.2-0.4,3.2-0.6
		c1.7-0.3,3.4-0.7,5.2-1c3-0.6,6-1.1,8.9-1.7c2.4-0.4,4.9-0.9,7.3-1.3c2-0.3,4-0.6,6-0.9c0.8-0.1,1.7-0.2,2.6-0.4
		c0.2,0,2.3-0.2,2.3-0.3c-1.2,4.8-5.1,23.3-12,56.3c0,0.4-5.7,1.6-6.3,1.8c-2.2,0.5-4.3,1-6.5,1.5c-2.1,0.4-4.2,0.8-6.2,1.2
		c-2,0.4-4,0.7-5.9,1.3c-0.3,0.1-0.7,0.2-0.8,0.5c-0.1,0.2-0.1,0.4,0,0.6c0.2,1,0.6,2,1.3,2.9c0.2,0.2,0.3,0.4,0.6,0.5
		c0.4,0.1,0.7,0.1,1,0c2-0.3,3.9-0.8,5.9-1.2c2-0.4,4-0.8,6-1.2c1.8-0.3,3.7-0.7,5.6-1c0.9-0.1,5.3-0.9,5.4-0.9
		c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.3,0.1,0.6,0.1,1,0.1c0.5,0,1-0.2,1.4-0.5c0.3-0.2,0.5-0.5,0.6-0.9
		c0.2,0,0.4-0.1,0.6-0.1c0.8-0.2,1.6-0.4,2.5-0.6c1.1-0.3,2.3-0.5,3.4-0.8c1.5-0.3,3-0.6,4.5-0.9c1.8-0.4,3.7-0.8,5.5-1.1
		c2.2-0.5,4.4-0.9,6.6-1.3c2.6-0.5,5.2-1,7.8-1.5c3-0.6,6-1.2,8.9-1.8c3.4-0.7,6.8-1.3,10.2-2c1.8-0.4,10.3-2.1,10.7-2
		c0.4,0,0.8,0.1,1.3,0.2c0.7,0.1,1.5,0,2.2-0.3c0.7-0.3,1.4-0.5,2-0.9c0.7-0.4,1.3-1,1.3-1.9l0-0.4c0-0.8-0.5-1.5-1.1-2
		c-0.7-0.6-1.9-0.7-2.8-0.6c-1.5,0.3-2.6,0.5-4,0.7c-1.8,0.3-3.5,0.6-5.3,0.9c-2.1,0.4-4.2,0.8-6.3,1.1c-2.4,0.5-4.8,0.9-7.3,1.4
		c-2.8,0.5-5.5,1-8.3,1.6c-3.1,0.6-6.2,1.2-9.3,1.8c-3.4,0.7-6.9,1.3-10.3,2c-3.8,0.7-7.6,1.5-11.4,2.2l12-56.1
		c4.1-1.6,34.1-6.2,45.5-8c11.3-0.7,12.5-1.6,12.6-3.4l0.1-1.5c0-0.6-1.2-0.8-1.7-1.1c-0.1,0-0.9-0.1-0.9-0.1
		c-2.1-0.1-3.3,0-5.2,0.2c-2.7,0.2-5.4,0.5-8,0.8c-3.6,0.4-7.2,1-10.8,1.5c-4.5,0.7-9.1,1.4-13.6,2.1c-5.5,0.9-11,1.9-16.5,2.8
		c0.5-3,2.1-10.7,6-29.1c0.8-0.2,1.6-0.3,2.4-0.5c3.5-0.6,6.9-1.1,10.4-1.7c5.5-0.9,11.1-1.7,16.7-2.5c3.5-0.5,7-1.1,10.5-1.6
		c1.9-0.3,3.8-0.6,5.8-0.9c1.8-0.3,3.5-0.7,5.4-0.3c0.4,0.1,0.8,0.2,1.1,0.2c0.5,0,1.1-0.1,1.6-0.3c0.9-0.3,1.9-0.8,2.3-1.7
		C578.7,41.5,578.9,40.5,578.5,39.3z"/>
                <path sx={{fill: t => t.colors.text}} d="M415.4,137.8c-0.5,0-1.7-0.1-42,7.7c-3.3,0.4-5.7,0.9-9.3,1.7c-4.2,0.9-10,2.2-20.8,4.1
		c-11.1,1.9-82.8,15-160.1,29.4c-35.3,6.6-70.6,13.9-106.2,18.6c-7.6,1-15.1,1.8-22.7,2.5c-11.3,0.9-23.7,1.1-32.9-5.5
		C14,191,9.9,182.3,8.2,173.5c-2.4-12.8-0.2-26.1,4.2-38.4C18.1,119.2,27,104,36.2,89.9c8.1-12.4,17.2-24.1,27.6-34.8
		c11.7-12,24.9-22.1,38.7-31.5c18.1-12.3,35.6-19.4,48-19.4c2.3,0,4.5,0.2,6.3,0.7c1,0.2,2.5,0.6,3.5,1.4c1.3,0.9,2.4,2,3.2,3.7
		c2,5,0.9,11.9-3.2,19.8c-3.8,7.9-10.2,16.4-18.5,24.7c-12.6,12.8-27.8,23.2-42.6,29.5c-6.6,2.9-0.8,6.7,6.4,3.5
		c14.1-6.3,28.6-16.3,41.1-28.9c7.9-7.8,14.5-16.6,18.5-24.6c4.2-8.5,5.2-16,2.7-21.5c-1.6-3.7-4.4-6.8-8.2-9.2
		c-2.3-1.2-4.1-2-5.9-2.4c-2.2-0.6-4.6-0.9-7.3-0.9c-12.9,0-30.8,7.1-49,19.5C72.8,36.3,46.5,57.6,22.6,99.6
		c-15,26.3-31,57.2-17.6,87.7c1.6,3.7,3.9,7.2,6.8,10.1c5.7,5.7,13.6,8.8,21.6,9.9c8,1.1,16.1,0.5,24.1-0.4
		c18.1-1.9,36.2-4.8,54.1-8c17.5-3.1,35-5.8,52.5-9c17.2-3.2,34.4-6.6,51.6-9.8c34.7-6.4,68.1-12.7,101.4-18.9
		c17.3-3.2,35-5.8,52.2-9.8l8.5-2c39.1-7,39.1-7.6,39.1-9.1l0-0.6l-0.7-1.9L415.4,137.8z"/>
                <path sx={{fill: t => t.colors.text}} d="M500.5,72.1l0-0.7l-0.8-2.1l-1.1,0c-0.6-0.1-1.9-0.1-42.1,7.6c0.1,0,0.7-1.8,0.7-2.1c-0.1-0.7-0.8-1.1-1.5-1.2
		c-1.2-0.1-2.7-0.3-3.6,0.6c-0.4,0.4-0.7,1-1,1.5c-0.1,0.2-0.6,2.1-0.7,2.1c-2.6,1-6.8,2.3-23,5.3c-6.2,1.1-12.5,2-18.6,3.3
		c-1,0.2-2.3,0.3-3.2,0.8c-0.2,0.1-0.5,0.2-0.7,0.4c-0.5,0.5-0.6,3.1,0.3,3.3c1.6,0.3,3.2,0.2,4.9,0c11.6-1.3,23.2-3.8,34.7-6
		l3.4-0.6c-1.7,2.7-4.5,6.5-8.3,11.5c-2.5,3.2-5.5,6-8.4,8.8c-11.9,11.2-29.3,22.2-46.5,18.8c-4.6-0.9-9-3.5-11.1-7.6
		c-1.3-2.6-1.6-5.7-1.6-8.7c0-0.8,0-1.5,0-2.3c0.6-19.6,11.7-36.2,24.6-50.1c4.6-4.9,10.4-9.1,15.8-13.2
		c10.7-8.1,23.5-16.7,37.5-16.1c0.5,0,1,0.1,1.4,0.3c0.8,0.4,1,1.4,1,2.3c0.3,4.7-1.5,9.5-3.3,13.8c-1.2,2.4-2.1,4.8-3.3,7.1
		c-1.2,2.3-2.5,4.6-3.7,6.9c-0.3,0.6-0.7,1.3-0.8,2c-0.2,1.1,0.3,2.1,0.9,3c0.5,0.7,1.4,1.3,2.1,0.9c0.3-0.1,0.4-0.4,0.6-0.7
		c3.5-5.5,6.8-11.2,9.3-17.2c1.8-4.3,3.6-9.1,3.7-13.8l0-0.4c0.2-5.9-2.8-9.5-8.5-10.6l-0.2,0c-9.8-0.5-23.7,6-42.3,19.4
		c-5.3,4.4-10.4,9.1-15.2,14.1c-11.8,12.5-22,27.7-24.2,45.1c-0.2,1.5-0.3,3-0.4,4.5c-3.1,3.5-6,7.1-9.5,10.3
		c-3.8,3.6-8,7.3-13.2,8.4c-0.6,0.1-1.3,0.2-2,0.1c-1-0.3-1.8-1.2-2.2-2.2c-1.5-3.9-0.4-7.8,0.8-11.6c1.3-4,3.1-7.9,5.4-11.5
		c0.2-0.3,0.4-0.7,0.2-1c-0.5-0.9-2.4-0.4-3.2-0.9c-0.7-0.4-1.1-1.3-1.6-1.9c-0.8-1.1-1.8-2.1-3.1-2.6c-3.1-1.5-9,1.1-11.7,2.7
		c-0.4,0.2-0.8,0.4-1.1,0.7c-6,3.6-9.6,6.7-14.3,12c-1.2,1.3-2.2,2.7-3.1,4.2c-0.5,0.8-0.9,1.5-1.3,2.3c-0.4,0.8-0.7,1.6-1.2,2.2
		c-2.5,2.8-5,5.5-7.7,8c-1.8,1.7-3.6,3.3-5.5,4.8c-2.2,1.7-4.9,3.7-7.8,3.7c-0.4,0-0.8,0-1.1-0.2c-0.6-0.4-0.7-1.2-0.7-1.9
		c-0.1-3.9,0.6-7.6,1.3-11.5c0.8-3.9,1.7-7.8,2.6-11.7c0.4-1.6,0.8-3.3,0.5-5c-0.4-2.3-2.2-4.2-4.6-3.8c-0.9,0.1-1.8,0.5-2.6,1
		c-4.3,2.4-8,6.4-11.3,10c-0.9,1-1.8,2-2.7,3c-0.9,1-1.7,2.2-2.8,3.1c0-1.1,0.1-2.7,0.1-3.8c-0.1-1.4-0.3-2.9-0.7-4.3
		c-0.5-1.9-1.8-3.7-3.9-3.7c-3-0.1-5.9,2.5-7.9,4.5c-1.5,1.4-2.9,2.9-4.3,4.4c-1.2,1.3-2.8,3.6-4.3,4.9c0.3-2.2,1.5-6.4,2-9.1
		c0.5-2.3,1-4.7,1.6-7c0.3-1.5-1.7-1.5-2.6-1.5c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.6,1.2-1.2,2.4-1.6,3.6
		c-0.6,0.8-1.2,1.7-1.5,1.9c-0.9,1.1-1.8,2.1-2.7,3.1c-5.1,5.9-10.5,11.7-16.5,16.7c-4.5,3.8-13.3,9.6-15.2,6.6s0.5-15.1,2.8-22.7
		c0.8-2.6,1.6-5.2,2.5-7.7c0.7-1.9,1.9-4.1,0.9-6.1c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.7,0-1,0
		c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0.2-0.5,0.5-0.7,0.8c-1.1,1.8-3.5,6.4-3.7,6.8c-2.1,3.3-14.4,23-21.2,32.8
		c-7.4,10.6-15.4,21-19,24.3c-1.2,1.1-6.4,4.5-7.5,1.7c-1.1-2.9,0-7.1,0.4-10c0.6-3.9,1.3-7.7,2.2-11.5c1.7-7.5,4.4-15,6.5-21.1
		c1.1-3.4,2.3-6.8,4-10c2.2-4.2,5.4-7.9,8.4-11.6c8.8-10.8,16.7-22.3,23.6-34.4c4.4-7.8,8.5-16.1,9.3-25.1c0.3-3.2-0.2-7.1-3-8.7
		c-1.9-1.1-4.3-0.7-6.2,0.3s-3.3,2.8-4.6,4.5c-4.1,5.7-7,12.1-9.9,18.4c-6.7,14.7-13.3,29.6-18.8,44.8c-4,11.1-8,23.1-17.2,30.6
		c-3.8,3-8.3,5.1-13,6.1c-3.7,0.7-8.2,0.5-10.6-2.4c-2.4-3-1.8-7.3-1-11c0.6-2.8,1.3-5.8,3.5-7.4c1.2-0.9,2.6-1.3,4-1.7
		c4.4-1.4,8.7-3.5,12.5-6.1c1.9-1.3,3.7-2.7,4.8-4.7s1.2-4.6-0.1-6.4c-2-2.6-5.9-2.4-8.9-1.1c-4.4,1.8-8,5.3-10.9,9.1
		c-3.9,5-6.8,10.7-8.6,16.7c-1.6,5.2-2.2,11.3,0.8,15.9c3.9,5.7,12.3,6.7,18.7,4s11.1-8.1,15.7-13.3c-2.8,7.9-5.8,19.8-6.9,29.9
		c-0.3,2.7-0.5,5.4,0.1,8c0.6,2.6,2.2,5,4.5,5.7c2.1,0.6,4.3-0.3,6.2-1.5c6.1-3.6,10.2-9.4,14.6-15.3c4.4-5.8,7.2-11.1,11.3-17.2
		c3.5-5.2,13.6-20.4,15.3-23c-0.8,3.6-1.5,7.1-2.2,10.7c-0.7,3.8-1.4,7.8,0.4,11.5c0.7,1.4,1.8,2.8,3.3,3.2c0.8,0.2,1.6,0.2,2.4,0.1
		c4.4-0.5,8.5-2.3,12.1-4.7c7.3-4.8,13.4-11.4,18.8-18.4c-0.5,0.7-0.6,2.3-0.9,3.1c-0.3,1.2-0.6,2.5-1,3.7c-0.6,2.5-1.3,5.1-1.9,7.6
		c-0.1,0.5-0.2,0.9,0,1.4c0.2,0.4,0.6,0.6,1,0.7s0.9,0,1.3,0c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.2-0.2,0.3-0.3
		c4.3-4.7,8.4-9.6,12.7-14.3c1.8-1.9,5.1-7.2,8.2-6.5c0.3,0.1-0.7,6.9-0.8,7.6c-0.3,1.8-0.6,3.6-1,5.4c-0.4,1.9-1.3,4.1-1.3,6
		c0,0.5,0.2,1,0.7,1.3c0.3,0.1,0.5,0.2,0.8,0.2c0.4,0,0.8,0,1.2,0c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.2,0.3-0.3
		c4.1-5.3,8.3-10.5,12.6-15.6c1.8-2.1,3.6-4.3,5.6-6.3c1.6-1.6,3.3-4.1,5.8-3.8c-1.5,6.4-2.5,12.9-3.8,19.3c-0.4,2-0.8,4.1-0.7,6.2
		c0.1,2.3,0.9,4.5,2.4,6.2c4.1,4.7,11,0.1,14.7-2.7c1.5-1.1,2.9-2.4,4.3-3.6c0.8-0.7,1.5-1.4,2.3-2.2c0.4-0.4,0.8-0.8,1.2-1.2
		c0.2-0.2,1.1-0.9,1.1-1.2c0,0.4-0.1,0.7-0.1,1.1c0,4,3.5,7.7,7.6,7.5c1.7-0.1,3.3-0.9,4.8-1.7c1.5-0.8,3-1.7,4.4-2.7
		c3-2.1,5.7-4.5,8.3-7c1-0.9,1.9-1.9,2.9-2.8c0.9-0.9,1.7-2.1,2.9-2.8c0.2,1,0.4,1.7,0.5,2.3c0.4,2.1,1.1,4.2,2.1,6.1
		c1.1,1.9,2.7,3.4,5,3.5c7.5,0.3,14.4-6.2,19.1-11.3c1.5-1.7,3-3.4,4.4-5.2c0.4-0.5,0.7-1,1.1-1.5c0.3,5,1.3,9.8,4.2,13.9
		c4,5.6,10.5,7.9,17.1,8.1c10.7,0.4,23.2-3.8,36.7-12.4c5-3.2,11-9.1,18.4-18.1c-1,3.2-2,6.4-2.8,9.6c-1.9,6.7-3.6,13.5-5.3,20.3
		c-1.6,6.4-3.1,12.9-4.6,19.3c-0.7,2.8-1.3,5.7-2,8.5c-0.3,1.3-0.6,2.6-1,3.8c-0.3,1.4-1,2.9-0.3,4.3c0.5,0.9,1.7,2,2.8,1.8
		c1-0.3,2.1-1.3,2.5-1.9c0.3-0.4,0.2-1,0.2-1.5c0-0.5,0.2-1.1,0.2-1.6c0.4-2.6,1-5.3,1.5-7.9c0.9-4.1,1.8-8.2,2.8-12.3
		c1.3-5.4,2.7-10.7,4.2-16.1c1.8-6.5,3.6-12.9,5.5-19.3c2.2-7.3,4.4-14.7,6.8-22l0.2-0.5c0.4-1.1,3.5-1.8,5.2-2.1
		C500.4,74.5,500.4,73.9,500.5,72.1z M194.4,45.3c3.7-8,8.3-13.4,11.6-15c0.6-0.2,1.1-0.4,1.5-0.4c0.3,2.3-0.2,6.1-2,11.4
		c-4.9,15.1-20.2,36.2-29.7,48.5C185.1,66.3,194.4,45.3,194.4,45.3z M132.8,104.5c1.5-3.4,5-7,7.7-8.4c1.6-0.9,3.3-1.3,4.9-1.3
		c0.8,0,1.6,0.1,2.3,0.3l1,0.3l-0.8,0.8c-2,1.9-4.1,3.6-6.2,5c-2.5,1.7-5.9,3.3-8.1,4.1l-1.4,0.5L132.8,104.5z M333.3,93.5
		c1.1-0.3,2.6-0.7,3.7-0.1c0.6,0.3,1.1,0.7,1.5,1.1c0.5,0.4,1,1,1.1,1.6c0.1,0.5-0.1,1.1-0.4,1.5c-3.1,5.8-6.3,11.4-10.5,16.5
		c-3.3,4-7.1,7.6-11.7,10c-1.2,0.6-2.5,1.2-3.8,1.6c-0.8,0.3-1.6,0.5-2.3,0.5s-1.4-0.4-1.9-1.2c-0.6-1.1-0.5-2.5-0.3-3.8
		c0.3-2.1,0.9-4.1,1.8-6c0.7-1.4,1.6-2.7,2.5-3.9c2.3-3.2,4.5-6.4,7.2-9.2c2.6-2.9,5.7-5.4,9.2-7.1"/>
            </g>
        </svg>
    )
}


export default Logo